<template>
  <div>
    <form @submit.prevent="submitCreateEnrollment">
      <div class="form-row">
        <div class="col-md-8">
          <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
            <div class="card-header">
              <h4 class="mb-0">
                Dados do aluno
                <small class="d-block mt-50">Confirme os dados do aluno antes de realizar a
                  matrícula.</small>
              </h4>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for=""> Nome completo </label>
                    {{ customer.full_name }}
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for=""> E-mail </label>
                    {{ customer.email }}
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-1 mb-md-0">
                    <label for=""> CPF </label>
                    {{ customer.cpf }}
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group" :class="customer.birth ? 'mb-1 mb-md-0' : 'mb-0 mb-md-0'">
                    <label for=""> Celular </label>
                    {{ customer.cellphone }} 
                    <i @click="$bvModal.show('modal-edit-cellphone')" class="bi bi-pencil-square ml-2 text-primary cursor-pointer"></i>
                  </div>
                </div>

                <div v-if="customer.birth" class="col-md-4">
                  <div class="form-group mb-0 mb-md-0">
                    <label for=""> Nascimento </label>
                    {{ customer.birth | datePT() }}
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="form-row">
                <div class="col-md-3">
                  <label><i class="text-danger bi bi-record-circle"></i> CEP</label>
                  <div class="form-group">
                    <input :class="{ 'is-invalid': $v.formCustomerAddress.zipcode.$error }" @keyup="searchCep()" v-mask="['#####-###']" type="text" v-model="formCustomerAddress.zipcode" class="form-control" />
                  </div>
                </div>

                <div class="col-md-6">
                  <label><i class="text-danger bi bi-record-circle"></i> Endereço</label>
                  <div class="form-group">
                    <input :class="{ 'is-invalid': $v.formCustomerAddress.address.$error }" type="text" v-model="formCustomerAddress.address" class="form-control" />
                  </div>
                </div>

                <div class="col-md-3">
                  <label>Número</label>
                  <div class="form-group">
                    <input type="text" v-model="formCustomerAddress.number" class="form-control" />
                  </div>
                </div>

                <div class="col-md-3">
                  <label>Complemento</label>
                  <div class="form-group mb-md-0">
                    <input type="text" v-model="formCustomerAddress.complement" class="form-control" />
                  </div>
                </div>

                <div class="col-md-3">
                  <label><i class="text-danger bi bi-record-circle"></i> Bairro</label>
                  <div class="form-group mb-md-0">
                    <input type="text" :class="{ 'is-invalid': $v.formCustomerAddress.neighborhood.$error }" v-model="formCustomerAddress.neighborhood" class="form-control" />
                  </div>
                </div>

                <div class="col-md-2">
                  <label><i class="text-danger bi bi-record-circle"></i> Cidade</label>
                  <div class="form-group mb-md-0">
                    <input type="text" :class="{ 'is-invalid': $v.formCustomerAddress.city.$error }" v-model="formCustomerAddress.city" class="form-control" />
                  </div>
                </div>

                <div class="col-md-2">
                  <label><i class="text-danger bi bi-record-circle"></i> UF</label>
                  <div class="form-group mb-md-0">
                    <input type="text" :class="{ 'is-invalid': $v.formCustomerAddress.uf.$error }" v-model="formCustomerAddress.uf" class="form-control" />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group mb-md-0">
                    <button :disabled="btnSubmitUpdateAddress" style="padding: 5px 5px !important; line-height: 2.3;" type="button" class="btn btn-save-address btn-block btn-warning" @click="submitUpdateAddress">
                      <div style="column-gap: 5px;" v-if="!btnSubmitUpdateAddress" class="d-flex items-center justify-content-center">
                        <i  class="bi bi-floppy"></i>
                        <span>
                          Salvar
                        </span>
                      </div>
                      <b-spinner v-else small class="align-middle"></b-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
            <div class="card-header">
              <h4 class="mb-0">
                Dados do atendente
                <small class="d-block mt-50">Verifique se seus dados estão corretos</small>
              </h4>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Nome completo</label>
                    {{ user.fullName }}
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>E-mail</label>
                    {{ user.email }}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label>CPF</label>
                    {{ user.cpf }}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label>Unidade</label>
                    <span v-if="user.unit">
                      {{ user.unit.title }}
                    </span>
                  </div>
                </div>

                <div class="col-md-12">
                  <label class="mt-2">Follow up</label>
                  <div class="form-group mb-0">
                    <textarea rows="3" class="form-control" v-model="form.note"
                      placeholder="Caso tenha necessidade, escreva aqui uma observação para esta venda."></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!customer.address" class="alert alert-danger p-1">
        <h5 class="text-danger d-flex-left justify-content-left" style="column-gap: 3px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M11.9998 16H12.0088" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 13L12 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <strong>Endereço do aluno ausente</strong>
        </h5>
        <p>Antes de gerar a matrícula, é necessário cadastrar o endereço do aluno. Preencha os campos acima com o endereço do aluno e clique no botão <u>Salvar</u>.</p>
      </div>

      <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Modalidade
              </label>
              <div class="form-group">
                <v-select label="title" :searchable="false" item-text="title" item-value="code" v-model="form.modality"
                  placeholder="Selecione" :options="optionsModalities" @input="modalitySelected"
                  :class="{ 'is-invalid': $v.form.modality.$error }">
                  <template v-slot:option="option">
                    <span v-html="option.title"></span>
                  </template>
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-8" v-if="form.modality && form.modality.title !== 'Passaporte'">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Turma
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" @search="fetchClassrooms" item-value="code" v-model="form.classroom"
                  placeholder="Digite o código da turma" :options="optionsClassrooms" @input="classroomSelected"
                  :class="{ 'is-invalid': $v.form.classroom.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-2">
              <label><i class="text-danger bi bi-record-circle"></i> Origem
                <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Onde está sendo feita a matrícula?'"></i>
              </label>
              <div class="form-group">
                <v-select :searchable="false" label="title" item-text="title" v-model="form.origin" item-value="code"
                  :options="optionsOrigins" :class="{ 'is-invalid': $v.form.origin.$error }" />
              </div>
            </div>

            <div class="col-md-7" v-if="form.modality && form.modality.title === 'Passaporte'">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Passaporte
              </label>
              <div class="form-group mb-0">
                <v-select label="title" item-text="title" @search="fetchProducts" item-value="code" v-model="form.passport"
                  placeholder="Digite o código da turma" :options="optionsProducts" @input="passaportSelected"
                  :class="{ 'is-invalid': $v.form.passport.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3" v-if="form.modality && form.modality.title === 'Passaporte'">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Unidade
              </label>
              <div class="form-group mb-0">
                <v-select label="title" :searchable="false" item-text="title" item-value="code" v-model="form.unit"
                  placeholder="Unidade" :options="optionsUnits"
                  :class="{ 'is-invalid': $v.form.unit.$error }">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div v-if="optionsLetterCredit.length && form.origin.title !== 'À distância'" class="col-md-6">
              <label>Carta de crédito</label>
              <div class="form-group mb-0">
                <v-select :searchable="false" v-model="form.letter" label="title" item-text="title" item-value="code" :options="optionsLetterCredit" />
              </div>
            </div>

            <div class="col-md-3" v-if="!form.letter || Number(form.letter.workloadMinutes) < form.classroomMinutes">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Forma de Pagamento
              </label>
              <div class="form-group">
                <v-select :searchable="false" label="title" item-text="title"
                  v-model="form.payment.method" item-value="code" :options="optionsPaymentMethods"
                  :class="{ 'is-invalid': $v.form.payment.method.$error }">
                  <template #option="{ code, title }">
                    <i class="bi mr-75" :class="getIconPaymentMethod(code)"></i>
                    <span> {{ title }}</span>
                  </template>
                </v-select>
              </div>
            </div>           

            <div v-if="form.payment.method && (form.payment.method.code === 'check' || form.payment.method.code === 'check')" class="col-md-3">
              <label><i class="text-danger bi bi-record-circle"></i> Nº do cheque</label>
              <div class="form-group">
                <input type="text" v-model="form.payment.check_number" class="form-control" />
              </div>
            </div>

            <div v-if="form.payment.method && (form.payment.method.code === 'check' ||
              form.payment.method.code === 'check') && form.origin.title !== 'À distância'" class="col-md-2">
              <label><i class="text-danger bi bi-record-circle"></i> Banco</label>
              <div class="form-group">
                <input type="text" v-model="form.payment.bank_name" class="form-control" />
              </div>
            </div>

            <div v-if="form.payment.method && (form.payment.method.code === 'check' ||
              form.payment.method.code === 'check') && form.origin.title !== 'À distância'" class="col-md-2">
              <label><i class="text-danger bi bi-record-circle"></i> Agência</label>
              <div class="form-group">
                <input type="text" v-model="form.payment.bank_agency" class="form-control" />
              </div>
            </div>

            <div v-if="form.payment.method && (form.payment.method.code === 'check' ||
              form.payment.method.code === 'check') && form.origin.title !== 'À distância'" class="col-md-2">
              <label><i class="text-danger bi bi-record-circle"></i> Conta bancária</label>
              <div class="form-group">
                <input type="text" v-model="form.payment.bank_account" class="form-control" />
              </div>
            </div>
            
            <div v-if="form.payment.method && (form.payment.method.code === 'card_credit' ||
              form.payment.method.code === 'card_debit') && form.origin.title !== 'À distância' && form.payment.method.code !== 'letter'" class="col-md-2">
              <label><i class="text-danger bi bi-record-circle"></i> Bandeira</label>
              <div class="form-group">
                <v-select :searchable="false" label="title" item-text="title" v-model="form.payment.brand"
                  item-value="code" :options="optionsCardNetworks" :class="{ 'is-invalid': $v.form.payment.brand.$error }"
                  :disabled="!form.payment.method" />
              </div>
            </div>

            <div class="col-md-2" v-if="form.origin.title !== 'À distância' && form.modality.title !== 'Passaporte' && (form.payment.method && (form.payment.method.code === 'card_credit' ||
              form.payment.method.code === 'card_debit'))">
              <label><i class="text-danger bi bi-record-circle"></i>
                Adquirente
                <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Maquininha usada ao efetuar o pagamento.'
                  "></i>
              </label>
              <div class="form-group mb-0">
                <v-select label="name" item-text="name" v-model="form.payment.acquirer" item-value="uuid"
                  :options="optionsAcquirers" :class="{
                    'is-invalid': $v.form.payment.acquirer.$error,
                  }" :searchable="false">
                  <template v-slot:option="option">
                    <span v-html="option.name"></span>
                  </template>
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4 pt-2" v-if="form.origin.title === 'À distância'">
              O cliente receberá via e-mail, o link do checkout exclusivo para efetuar o pagamento.
            </div>
          </div>
        </div>
      </div>
      
      <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-primary px-2 py-2" v-if="manager.status === 0 && manager.sent">
        <i class="bi bi-hourglass-split" style="margin-right: 5px;"></i> A solicitação foi enviada via WhatsApp para os gestores. <strong><u>NÃO ATUALIZE A PÁGINA!</u></strong>.
      </div>

      <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-success px-2 py-2" v-if="manager.status === 1">
        <i class="bi bi-check-circle" style="margin-right: 5px;"></i> Sua solicitação de <strong>{{ form.discount.percent }}% </strong> de desconto foi <strong class="text-success">APROVADA</strong>!
      </div>

      <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-danger px-2 py-2" v-if="manager.status === 2">
        <i class="bi bi-x-circle" style="margin-right: 5px;"></i> A sua solictação de desconto foi <strong class="text-danger">REPROVADA</strong>!
      </div>

      <div v-if="!form.letter || Number(form.letter.workloadMinutes) < form.classroomMinutes" class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
        <div class="card-header mb-0">
          <div class="form-row">
            <div class="col-md-8">
              <h4 class="mb-0">
                Pagamento
                <small class="d-flex justify-content-left items-center mt-50 text-dark">
                  <strong>Ao selecionar o parcelamento, as opções de desconto serão habilitadas </strong>
                </small>
              </h4>
            </div>

            <div class="col-md-4 d-flex-left justify-content-end" style="column-gap: 5px;">
              <button :disabled="!form.payment.installment" type="button" @click="$bvModal.show('modal-discount-apply')" class="btn btn-primary">
                <i class="bi bi-tag mr-50"></i>
                Aplicar
              </button>

              <button :disabled="!form.payment.installment" type="button" @click="getDiscount" class="btn btn-primary">
                <i class="bi bi-chat-dots mr-50"></i>
                Solicitar
              </button>

              <button type="button" @click="$bvModal.show('modal-discounts-categories')" class="btn btn-light">
                <i class="bi bi-info-circle"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="card-body p-2">
          <div class="form-row">
            <div class="col-md-9">
              <label><i class="text-danger bi bi-record-circle"></i>
                Parcelamento</label>
              <div class="form-group mb-md-0">
                <v-select label="title" item-text="title" v-model="form.payment.installment" item-value="code"
                  :options="optionsInstallments" :class="{
                    'is-invalid': $v.form.payment.installment.$error,
                  }" :searchable="false" @input="installmentSelected">
                  <template v-slot:option="option">
                    <span v-html="option.title"></span>
                  </template>
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <label>
                Código da Venda
                <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Código da venda, gerado ao efetuar o pagamento na adquirente.'
                  "></i>
              </label>
              <div class="form-group mb-md-0">
                <input type="text" class="form-control" v-model="form.payment.transaction_id" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="form.payment && form.payment.installment && form.payment.installment.total"
        class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
        <div class="card-header mb-0">
          <h4 class="mb-0">
            Parcelas
            <small class="d-block mt-50">Caso o cliente queira efetuar o pagamento de 1 ou mais parcelas,
              usando formas de pagamento diferentes, é só fazer desmembramento.
            </small>
          </h4>
        </div>

        <div class="card-body p-0">
          <b-table sticky-header class="position-relative" :items="optionsInstallmentsSelected" responsive
            :fields="tableColumnsInstallments" show-empty striped hover
            empty-text="Nenhuma parcela foi selecionada até o momento">
            <template #cell(installment)="{ item }">
              {{ item.installment }}
            </template>
            <template #cell(value)="{ item }">
              {{ toCurrency(parseFloat(item.value)) }}
            </template>
            <template #cell(date)="{ item }">
              <input type="text" class="form-control" v-model="item.date">
            </template>
            <!-- <template #cell(discount)="{ item }">
              <span v-if="item.discountTotal">
                <strong>
                  {{ toCurrency(parseFloat(item.discountTotal)) }}
                </strong>
                 - 
                {{ item.discount }}%
              </span>

              <span v-else>0%</span>
            </template> -->
            <template #cell(payments)="data">
              <b-badge variant="dark">
                {{ data.item.payments.length }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <button @click="dismemberInstallment(data.item)" type="button" class="btn btn-light btn-sm">
                <i class="bi bi-three-dots-vertical"></i>
              </button>
            </template>
          </b-table>
        </div>
      </div>

      <div v-if="form.letter && form.letter.passport === 1" class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1">
        <div class="card-header mb-0">
          <h4 class="mb-0">
            Carta de crédito
            <small class="d-block mt-50">
              Detalhes sobre o uso da cartá de crédito do aluno após a matrícula do aluno.
            </small>
          </h4>
        </div>

        <div class="card-body p-2">
          <div class="form-row" v-if="form.letter.passport">
            <div class="col-md-3">
              <strong>Total de horas: </strong>
              <span>{{ letterCredit.originalHours }}h</span>
            </div>
            <div class="col-md-3">
              <strong>Carga horária da turma: </strong>
              <span>{{ form.classroom.workload }}</span>
            </div>
           
            <div class="col-md-3">
              <strong>Horas a usar: </strong>
              <span>{{ letterCredit.usedHours }}h</span>
            </div>
            <div class="col-md-3">
              <strong>Horas restantes: </strong>
              <span>{{ letterCredit.remainingHours }}h</span>
            </div>
          </div>
<!-- 
          <div class="form-row" v-else-if="!form.letter.passport">
            <div class="col-md-4">
              <strong>Total: </strong>
              <span>{{ form.letter.total | toCurrency }}</span>
            </div>

            <div class="col-md-4">
              <strong>Valor usado: </strong>
              <span>{{ letterCredit.totalUsed | toCurrency }}</span>
            </div>

            <div class="col-md-4">
              <strong>Valor restante: </strong>
              <span>{{ letterCredit.totalRemaining | toCurrency }}</span>
            </div>
          </div> -->
        </div>
      </div>

      <div class="form-row justify-content-end modal-footer-custom">
        <div class="col-md-2 col-12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-2"
            type="submit"
            block
            :disabled="!customer.address || (submited || manager.status === 0)"
          >
            <div v-if="submited">
              <b-spinner small variant="light" />
            </div>
            <div v-else>Concluir matrícula</div>
          </b-button>
        </div>
      </div>
    </form>

    <b-modal id="modal-edit-cellphone" modal-class="modal-edit-cellphone" title="Editar celular" centered
      hide-footer no-close-on-backdrop>
      <div class="form-row">
        <div class="col-md-8">
          <input
            v-mask="['(##) #####-####', '(##) ####-####']"
            type="tel"
            v-model="formCustomerAddress.cellphone"
            name="cellphone"
            placeholder="(00) 00000-0000"
            class="form-control"
          />
        </div>

        <div class="col-md-4">
          <button :disabled="btnSubmitUpdateAddress" style="padding: 5px 5px !important; line-height: 2.5;" type="button" class="btn btn-block btn-warning" @click="submitUpdateAddress">
            <i v-if="!btnSubmitUpdateAddress" class="bi bi-floppy"></i>
            <b-spinner v-else small class="align-middle"></b-spinner>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal hide-header-close id="modal-dismember-installment" modal-class="modal-dismember"
      :title-html="`Desmembrar <strong>${dismember.installment}ª parcela</strong>`" centered hide-footer
      no-close-on-backdrop body-class="p-0">
      <b-card-body>
        <div class="form-row">
          <div class="col-md-4">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Forma de Pagamento
            </label>
            <div class="form-group mb-md-0">
              <v-select :searchable="false" label="title" :class="{ 'is-invalid': $v.formDismember.method.$error }" item-text="title" v-model="formDismember.method"
                item-value="code" :options="optionsPaymentMethods" @input="formDismember.bran = ''">
                <template #option="{ code, title }">
                  <i class="bi mr-75" :class="getIconPaymentMethod(code)"></i>
                  <span> {{ title }}</span>
                </template>
              </v-select>
            </div>
          </div>

          <div v-if="(formDismember.method && formDismember.method.code === 'letter')" class="col-md-5">
            <label><i class="text-danger bi bi-record-circle"></i> Carta de crédito</label>
            <div class="form-group mb-md-0">
              <v-select :searchable="false" v-model="formDismember.letter" label="title" item-text="title" item-value="code" :options="optionsLetterCredit" />
            </div>
          </div>

          <div v-if="(formDismember.method && formDismember.method.code === 'letter')" class="col-md-2">
            <label><i class="text-danger bi bi-record-circle"></i> Crédito</label>
            <div class="form-group mb-md-0">
              <money class="form-control" :class="{ 'is-invalid': $v.formDismember.value.$error }" v-model="formDismember.value" placeholder="0,00" v-bind="money" @input="limitLetter" />
            </div>
          </div>

          <div class="col-md-4" v-if="formDismember.method.title === 'Cartão de Crédito' || formDismember.method.title === 'Cartão de Débito'">
            <label>
              <i class="text-danger bi bi-record-circle"></i> Bandeira
            </label>
            <div class="form-group mb-md-0">
              <v-select :class="{ 'is-invalid': $v.formDismember.brand.$error }" :disabled="formDismember.method.title !== 'Cartão de Crédito' &&
                formDismember.method.title !== 'Cartão de Débito'
                " label="title" item-text="title" item-value="code" v-model="formDismember.brand"
                :options="optionsCardNetworks" :searchable="false">
              </v-select>
            </div>
          </div>
            
          <div class="col-md-3" v-if="(formDismember.method && formDismember.method.code !== 'letter')">
            <label>
              <i class="text-danger bi bi-record-circle"></i> Valor
            </label>
            <div class="form-group mb-md-0">
              <money :class="{ 'is-invalid': $v.formDismember.value.$error }" class="form-control" v-model="formDismember.value" placeholder="0,00" v-bind="money" />
            </div>
          </div>
          <div class="col-md-1 d-flex-left">
            <button class="btn btn-sm btn-primary" style="line-height: 2.3; margin-top: 20px" type="button"
              @click="addDismemberPayment" :disabled="this.dismember.subtotal >= this.dismember.value">
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
        </div>
        <hr />
        <h5 class="mb-0">Desmembramento</h5>
        <p class="mb-2 mt-50">
          Cadastre os pagamentos referente a parcela
          <strong>{{ dismember.installment }}</strong>. A soma dos pagamentos tem que totalizar  {{ dismember.value | toCurrency }}.
        </p>
        <div class="form-row" v-for="(element, index) in dismember.payments" :key="index">
          <div class="col-md-4">
            <div class="form-group">
              <v-select label="title" item-text="title" item-value="code" v-model="element.method"
                :options="optionsPaymentMethods" :searchable="false" placeholder="Método de pagamento" :disabled="true">
              </v-select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <v-select label="title" item-text="title" item-value="code" v-model="element.brand"
                :options="optionsCardNetworks" :searchable="false" placeholder="Bandeira" :disabled="true">
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <money class="form-control" v-model="element.value" placeholder="0,00" v-bind="money" :disabled="true">
              </money>
            </div>
          </div>

          <div class="col-md-1">
            <button type="button" style="padding: 4px 9px !important; height: 44px"
              class="btn btn-block btn-sm text-center btn-danger text-danger" @click="removeAtDismember(index)">
              <feather-icon icon="XCircleIcon" size="16" />
            </button>
          </div>
        </div>
        <p class="mb-0" v-if="dismember.payments.length === 0">
          Nenhum desmembramento foi cadastrado até o momento.
        </p>
      </b-card-body>

      <b-card-footer>
        <div class="form-row">
          <div class="col-md-10 text-right">Cadastrado até o momento:</div>
          <div class="col-md-2 text-right text-primary">
            <strong>{{ dismember.subtotal | toCurrency }}</strong>
          </div>
        </div>

        <div class="form-row mt-50">
          <div class="col-md-10 text-right">Pagamento restante:</div>
          <div class="col-md-2 text-right text-danger">
            <strong>{{
              (dismember.value - dismember.subtotal) | toCurrency
            }}</strong>
          </div>
        </div>

        <div class="form-row mt-50">
          <div class="col-md-10 text-right">Total da parcela:</div>
          <div class="col-md-2 text-right text-success">
            <strong>{{ dismember.value | toCurrency }}</strong>
          </div>
        </div>
        <hr>
        <div class="form-row justify-content-end mt-50">
          <div class="col-md-3">
            <button @click="closeModalDismember" type="button" class="btn btn-block btn-light">
              Limpar
            </button>
          </div>

          <div class="col-md-3">
            <button @click="closeModalSaveDismember" type="button" class="btn btn-block btn-success">
              Salvar
            </button>
          </div>
        </div>
      </b-card-footer>
    </b-modal>

    <b-modal id="modal-discount-request" modal-class="modal-customer-add-product" title="Solicitar desconto" centered
      hide-footer no-close-on-backdrop>
      <form @submit.prevent="submitGetDiscount">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <p class="text-success mb-0">
              O desconto será aplicado após aprovação do gestor.
            </p>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Quantos % deseja conceder?
              </label>
              <input type="number" @input="maxRequestDiscount(formRequestDiscount.percent)" max="100" class="form-control" :class="{ 'is-invalid': $v.formRequestDiscount.percent.$error }" placeholder="%" v-model="formRequestDiscount.percent" :disabled="manager.loading"
                pattern="\d*" />
            </div>
          </div> 

          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo do desconto
              </label>
              <textarea  :class="{ 'is-invalid': $v.formRequestDiscount.note.$error }" v-model="formRequestDiscount.note" class="form-control"></textarea>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <button @click="closeModalDiscountRequest" type="button" class="btn btn-light btn-block"
              style="line-height: 1.3">
              Cancelar
            </button>
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-success btn-block"
              style="line-height: 1.3">
              Solicitar
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="modal-discount-apply" modal-class="modal-customer-add-product" title="Conceder desconto" centered
      hide-footer no-close-on-backdrop>
      <form @submit.prevent="submitGiveDiscount">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <p class="text-success mb-0">
              Você tem permissão para conceder até
              <strong>{{ discount.max }}%</strong> de desconto.
            </p>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Quantos % deseja conceder?
              </label>
              <input type="number" :class="{ 'is-invalid': $v.formGiveDiscount.percent.$error }"  @input="maxDiscount" :max="discount.max" class="form-control" placeholder="%" v-model="formGiveDiscount.percent"
                pattern="\d*" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo do desconto
              </label>
              <textarea v-model="formGiveDiscount.note" :class="{ 'is-invalid': $v.formGiveDiscount.note.$error }" class="form-control"></textarea>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <button @click="closeModalDiscountOfUser()" type="button" class="btn btn-light btn-block"
              style="line-height: 1.3">
              Cancelar
            </button>
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-success btn-block"
              style="line-height: 1.3">
              Conceder desconto
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal centered id="modal-discounts-categories" title="Permissão para descontos" hide-footer>
      <b-card-text>
        <p>
          Veja abaixo o percentual de desconto que você poderá conceder para cada categoria de produto.
        </p>
        <b-list-group class="list-group-messages">
          <b-list-group-item v-for="(element, index) in userDiscounts" :key="index">
            <div class="row">
              <div class="col-md-8 text-left col-8">
                {{ element.name }}
              </div>
              <div class="col-md-4 text-center col-4"> Até {{ element.max }}% </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BModal,
  BAvatar,
  BLink,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BTable,
  BCardBody,
  BCardFooter,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BSpinner,
  BButton,
  BInputGroup,
  BInputGroupAppend
} from "bootstrap-vue";

import moment from "moment";

import {
  required,
  requiredIf,
  minValue,
  minLength,
} from "vuelidate/lib/validators";

import vSelect from "vue-select";
import { maskCpf, convertWorkloadToMinutes, converterMinutesInHours, converterHourToPrice, calculateCreditTotal, calculateCourseHourPrice } from "@/_helpers/_urlHelper";
import draggable from "vuedraggable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { Money } from "v-money";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';

import io from 'socket.io-client';
import letterCredit from "@/store/letter-credit";

export default {
  components: {
    BCard,
    BBadge,
    BCardText,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    draggable,
    ButtonsActionsFooter,
    BModal,
    BAvatar,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BTable,
    BCardBody,
    BCardFooter,
    Money,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      submited: false,
      product: "",
      managerSelected: "",
      discount: {
        min: 0,
        max: 0,
      },
      manager: {
        loading: false,
        discount: 0,
        status: '',
        message: "",
        sent: 0,
        description: "",
      },
      formDismember: {
        method: "",
        brand: "",
        value: 0,
      },
      dismember: {
        installment: "",
        value: "",
        payments: [],
        subtotal: 0,
      },
      enrollment: "",
      requestDiscountStatus: 0,
      tableColumnsInstallments: [
        {
          key: "installment",
          label: "Nº Parcela",
          class: "text-center",
          thStyle: "width: 140px",
        },
        {
          key: "date",
          label: "Data",
          class: "text-center",
          thStyle: "width: 160px",
        },
        {
          key: "value",
          label: "Valor",
          class: "text-center",
        },
        {
          key: "payments",
          label: "Desmembramentos",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      sessionId: '',
      tableColumns: [
      {
          key: "name",
          label: "Gestor",
          thStyle: "width: 400px",
          class: "text-elipse-400",
        },
        {
          key: "discount",
          label: "Desconto",
          class: "text-elipse-400 text-center",
        },
      ],
      form: {
        mode: 0,
        payment: {
          method: "",
          installment: "",
          brand: "",
          transaction_id: "",
          acquirer: "",
          check_number: "",
          bank_agency: "",
          bank_account: "",
          bank_name: "",
        },
        letter: "",
        letter_total: 0,
        installments: [],
        note: "",
        modality: "",
        unit: "",
        classroom: "",
        passport: "",
        disciplines: [],
        origin: "",
        product: "",
        discount: {
          percent: 0,
          note: '',
          total: 0,
        },
        discount_note: "",
        workload: 0,
        disciplinesSelectedsTotal: 0,
        disciplinesSelectedsMinutes: 0,
        classroomMinutes: 0,
      },
      installmentSelect: '',
      optionsClassrooms: [],
      optionsDisciplinesClassroom: [],
      optionsProducts: [],
      optionsPaymentMethods: [],
      optionsInstallments: [],
      installments: [],
      optionsModalities: [],
      optionsUnits: [],
      optionsCardNetworks: [],
      managers: [],
      optionsInstallmentsSelected: [],
      optionsAcquirers: [],
      optionsOrigins: [],
      optionsProducts: [],
      optionsLetterCredit: [],
      customer: "",
      user: "",
      btnSubmitUpdateAddress: false,
      formCustomerAddress: {
        zipcode: "",
        cellphone: "",
        address: "",
        complement: "",
        number: "",
        city: "",
        neighborhood: "",
        uf: ""
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      userDiscounts: '',
      formGiveDiscount: {
        percent: 0,
        note: '',
      },
      formRequestDiscount: {
        percent: 0,
        note: '',
      },
      intervalId: null,
      letterCredit: {
        total: 0,
        totalRemaining: 0,
        totlUsed: 0,

        passport: 0,
        originalMinutes: 0,
        originalHours: '00:00',

        remainingMinutes: 0,
        remainingHours: '00:00',

        usedMinutes: 0,
        usedHours: '00:00',
      },
      disciplinesSelecteds: [],
      disciplines: [],
    };
  },
  created() {
    // Inicializa a conexão do socket no hook 'created'
    this.socket = io(`${process.env.VUE_APP_API_URL}`);

    // Listener para eventos de conexão
    this.socket.on('connect', () => {
      this.socketStatus = 'conectado';
      console.log('Conectado ao servidor Socket.io');
    });

    // Listener para eventos de desconexão
    this.socket.on('disconnect', () => {
      this.socketStatus = 'desconectado';
      console.log('Desconectado do servidor Socket.io');
    });

    // Listener para eventos personalizados do servidor
    this.socket.on('message', (data) => {
      console.log('Mensagem recebida do servidor:', data);
    });
  },
  validations: {
    formCustomerAddress: {
      address: {
        required,
      },
      neighborhood: {
        required,
      },
      city: {
        required,
      },
      uf: {
        required,
      },
      zipcode: {
        required,
      },
    },
    form: {
      classroom: {
        required: requiredIf((vm) => {
          return vm.modality && vm.modality.title !== "Passaporte"
            ? true
            : false;
        }),
      },
      passport: {
        required: requiredIf((vm) => {
          return vm.modality && vm.modality.title === "Passaporte"
            ? true
            : false;
        }),
      },
      origin: {
        required,
      },
      mode: {
        required,
      },
      payment: {
        brand: {
          required: requiredIf((vm) => {
            return (vm.payment && vm.payment.method.code === "card_credit" && vm.origin && vm.origin.title !== 'À distância') || vm.letter && Number(vm.letter.workloadMinutes) < Number(vm.classroom.workloadMinutes)
              ? true
              : false;
          }),
        },
        method: {
          required: requiredIf((vm) => {
            return vm.letter && Number(vm.letter.workloadMinutes) < Number(vm.classroom.workloadMinutes)
              ? true
              : false;
          }),
        },
        installment: {
          required: requiredIf((vm) => {
            return vm.letter && Number(vm.letter.workloadMinutes) < Number(vm.classroom.workloadMinutes)
              ? true
              : false;
          }),
        },
        acquirer: {
          required: requiredIf((vm) => {
            return vm.origin && (vm.origin.title !== 'À distância' && vm.modality.title !== 'Passaporte')
              ? true
              : false;
          }),
        },
      },
      unit: {
        required: requiredIf((vm) => {
          return vm.modality && vm.modality.title === 'Passaporte' ? true : false;
        }),
      },
      modality: {
        required,
      },
    },
    formGiveDiscount: {
      percent: {
        required,
      },
      note: {
        required,
        minLength: minLength(5)
      },
    },
    formRequestDiscount: {
      percent: {
        required,
      },
      note: {
        required,
        minLength: minLength(5)
      },
    },
    formDismember: {
      method: {
        required,
      },
      brand: {
        required: requiredIf((vm) => {
          return vm.method.title === 'Cartão de Crédito' || vm.method.title === 'Cartão de Débito' ? true :false
        })
      },
      value: {
        required,
        minValue: minValue(0.1)
      },
    }
  },
  mounted() {
    this.getData();

    this.user = JSON.parse(localStorage.getItem("userData"));
    this.user.cpf = maskCpf(this.user.cpf);
    
    this.fetchUserDiscounts();
    
    this.sessionId = this.generateUniqueNumber();

    this.$root.$on("bv::modal::hidden", (event) => {
      if (event.componentId === "modal-dismember-installment") {
        this.clearFormDismember();
        this.dismember.subtotal = 0;
      }
    });

    this.socket.addEventListener("manager-discount:action", (event) => {
      this.manager.status = event.status
      // this.$socket.emit("manager-get-discount");
    });

    this.$store.dispatch("CardNetwork/forSelect").then((res) => {
      this.optionsCardNetworks = res;
    });

    this.$store.dispatch("Order/forSelectOrigins", [1,2]).then((res) => {
      this.optionsOrigins = res;
    });
  },
  watch: {
    "formDismember.value": function (newValue, oldValue) {
      let subtotal = this.dismember.subtotal;
      subtotal = subtotal + newValue;

      if (subtotal > this.dismember.value) {
        this.formDismember.value = 0;
      }
    },
    "form.payment.installment": function (installment) {
      if (this.letterCredit.passport === 0) {
        // Converte para números e arredonda para 2 casas
        const totalLetterCredit = Number(this.form.letter.total || 0).toFixed(2);

        const paymentTotal = Number(installment.originalTotal || 0).toFixed(2);

        // Quanto foi usado da carta de crédito
        this.letterCredit.totalUsed = Math.min(totalLetterCredit, paymentTotal);


        // Valor restante da carta de crédito
        this.letterCredit.totalRemaining = totalLetterCredit - this.letterCredit.totalUsed;

        // Quanto terei que pagar a mais (se pagamento > cartaCredito)
        // const valorAPagarAMais = Math.max(0, paymentTotal - totalLetterCredit);
      }
    },
    "manager.status": function (newValue, oldValue) {
      if (newValue === 1 || newValue === 2) {
        this.stopFetching()

        if (newValue === 1) {          
          const discount = this.formRequestDiscount;

          for (let index = 0; index < this.optionsInstallments.length; index++) {
            if (this.optionsInstallments[index].code === this.form.payment.installment.code) {
              
              const total = this.optionsInstallments[index].total;
            
              const totalDiscount = parseFloat((total * discount.percent) / 100).toFixed(2);

              discount.total = totalDiscount;

             this.optionsInstallments[index].total  = parseFloat(total - discount.total).toFixed(2);
             this.optionsInstallments[index].value = parseFloat(this.optionsInstallments[index].total / this.optionsInstallments[index].installments).toFixed(2)
             this.optionsInstallments[index].installments = this.optionsInstallments[index].installments;
             this.optionsInstallments[index].totalDiscount = totalDiscount;
             this.optionsInstallments[index].note = discount.note
            
             this.optionsInstallments[index].title = `${this.optionsInstallments[index].installments}x de ${this.toCurrency(
                parseFloat(this.optionsInstallments[index].value)
              )} = ${this.toCurrency(parseFloat(this.optionsInstallments[index].total))} ${discount.percent ? `(com -${discount.percent}%)` : ''}`

              this.form.payment.installment = this.optionsInstallments[index]
              
              this.optionsInstallmentsSelected.map((el) => {
                el.value = parseFloat(this.optionsInstallments[index].value);
              })
            }          
          }
          
          this.form.discount = {
            percent: discount.percent,
            total: discount.total,
            note: discount.note,
          }

          this.notify(
            "Desconto aplicado com sucesso.",
            "CheckIcon",
            "success",
            null,
            10000
          );
        }
      }
    },
   "form.payment.method": function (newValue, oldValue) {
      this.formDismember.brand = "";
      this.optionsInstallmentsSelected = [];
      this.form.payment.installment = "";
      this.form.payment.brand = "";
      this.form.installments = [];
      
      this.optionsInstallments = [];

      if (!newValue) return false

      this.generateInstallments()
    },
    "form.classroom": function(newValue) {
      this.disciplines = [];
      this.optionsPaymentMethods = [];
      this.installments = [];
      this.optionsInstallments = [];
      this.optionsInstallmentsSelected = [];
      this.form.installments = "";
      this.form.payment.installment = "";
      this.form.payment.method = "";
      this.form.origin = "";
      this.form.letter = "";
      this.form.letter_total = 0;
      this.form.workload = 0;
      this.form.disciplinesSelectedsTotal = 0;
      this.disciplines = newValue.disciplines
      this.resetInfosLetterCredit()
    },
    "form.letter": function (newValue) {
      if (!newValue) {
        this.formDismember.brand = "";
        this.optionsInstallmentsSelected = [];
        this.form.payment.installment = "";
        this.form.payment.brand = "";
        this.form.installments = [];
        this.form.letter = "";
        this.optionsInstallments = [];
        this.form.payment.method = "";
      }

      this.refreshTimeLetterCredit()
    },
   "form.modality": function (modalitySelected) {
      // Define um mapeamento entre modality.title e element.name
      const modalityMap = {
        "Live": "Curso Live",
        "Presencial": "Curso Presencial",
        "Passaporte": "Passaporte"
      };

      // Encontra o desconto correspondente ou define null se não houver
      this.discount = this.userDiscounts.find(
        element => element.name === modalityMap[modalitySelected.title]
      ) || null;
    },
  },
  beforeDestroy() {
    this.stopFetching()

    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    resetInfosLetterCredit() {
      this.letterCredit = {
        passport: 0,

        originalMinutes: 0,
        originalHours: '00:00',

        remainingMinutes: 0,
        remainingHours: '00:00',

        usedMinutes: 0,
        usedHours: '00:00',
      }
    },
    refreshTimeLetterCredit() {
      this.resetInfosLetterCredit()

      if (this.form.classroom && this.form.letter) {
        this.letterCredit.originalMinutes = Number(this.form.letter.workloadMinutes)
        this.letterCredit.originalHours = converterMinutesInHours(this.letterCredit.originalMinutes)
        this.letterCredit.passport = this.form.letter.passport

        if (
          this.letterCredit.passport === 1 && 
          this.letterCredit.originalMinutes >= this.form.classroomMinutes
        ) {
          this.form.payment.method = this.optionsPaymentMethods.find((el) => el.title === "Carta de Crédito");
         
          this.letterCredit.remainingMinutes = this.letterCredit.originalMinutes - this.form.classroomMinutes;
          this.letterCredit.remainingHours = converterMinutesInHours(this.letterCredit.remainingMinutes);

          this.letterCredit.usedMinutes = this.letterCredit.originalMinutes - this.letterCredit.remainingMinutes;
          this.letterCredit.usedHours = converterMinutesInHours(this.letterCredit.usedMinutes);

          if (this.letterCredit.remainingMinutes < 1) {
            this.letterCredit.usedMinutes = this.letterCredit.originalMinutes
          }
        }
      } else if(this.form.letter && this.letterCredit.passport === 0) {
        // Converte para números e arredonda para 2 casas
        const totalLetterCredit = Number(this.form.letter.total || 0).toFixed(2);

        const paymentTotal = Number(installment.originalTotal || 0).toFixed(2);

        // Quanto foi usado da carta de crédito
        this.letterCredit.totalUsed = Math.min(totalLetterCredit, paymentTotal);

        // Valor restante da carta de crédito
        this.letterCredit.totalRemaining = totalLetterCredit - this.letterCredit.totalUsed;
      }
    },
    // Atualizar cálculos quando seleção muda
    updateDisciplinesSelecteds() {
      this.disciplinesSelecteds = []
      // Atualizar array de disciplinas selecionadas
      this.disciplinesSelecteds = this.disciplines.filter(d => d.selected);
      
      // Calcular total da carga horária em minutos
      const totalMinutes = this.disciplinesSelecteds.reduce((sum, d) => {
        return sum + convertWorkloadToMinutes(d.workload);
      }, 0);

      // Calcular total da carga horária de todas as disciplinas
      const totalMinutesAll = this.disciplines.reduce((sum, d) => {
        return sum + convertWorkloadToMinutes(d.workload);
      }, 0);

      // Converter minutos totais para formato HH:MM
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      this.form.workload = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      // Calcular preço total proporcional às disciplinas selecionadas
      this.form.disciplinesSelectedsTotal = (totalMinutes / totalMinutesAll) * this.form.payment.installment.total;

      this.generateInstallments()
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [7],
          })
          .then((res) => {
            this.optionsProducts = res.data;
          });
      }
    },
    // applyLetter() {
    //   this.form.workload = 0;
    //   this.form.disciplinesSelectedsTotal = 0;
      
    //   // Atualizar array de disciplinas selecionadas
    //   this.form.disciplines = this.form.classroom.disciplines.filter(d => d.selected = false);

    //   if (this.form.letter_total > 0) {
    //     this.optionsInstallments.forEach((el) => {
    //       const total = el.total
    //       el.total =  total  - this.form.letter_total
        
    //       if (el.total > 0) {
    //         el.total_original = total
    //         el.letter_total = this.form.letter_total
    //         el.value = el.total / el.installments
    //         el.title = `${el.installments}x de ${this.toCurrency(parseFloat(el.value))} = ${this.toCurrency(parseFloat(el.total))} (-${this.toCurrency(parseFloat(this.form.letter_total))} da Carta de crédito)`
    //       } else {
    //         el.total_original = total
    //         el.letter_total = this.form.letter_total
    //         el.value = 0
    //         el.title = `R$ 0,00 (-${this.toCurrency(parseFloat(this.form.letter_total))} da Carta de crédito)`
    //       }
    //     })
    //   }    
    // },
    limitLetter(e) {
      if (e > 0 && e > this.form.letter.total) {
        this.form.letter_total = 0
      }
    },
    // letterSelected(letter) {
    //   this.optionsInstallments.forEach((el) => {
    //     if (letter.total > 0) {
    //       const total = el.total
    //       el.total =  total  - letter.total
        
    //       if (el.total > 0) {
    //         el.total_original = total
    //         el.letter_total = letter.total
    //         el.value = el.total / el.installments
    //         el.title = `${el.installments}x de ${this.toCurrency(parseFloat(el.value))} = ${this.toCurrency(parseFloat(el.total))} (-${this.toCurrency(parseFloat(letter.total))} da Carta de crédito)`
    //       } else {
    //         el.total_original = total
    //         el.letter_total = letter.total
    //         el.value = 0
    //         el.title = `R$ 0,00 (-${this.toCurrency(parseFloat(letter.total))} da Carta de crédito)`
    //       }
    //     }
    //   })
    // },
    // OriginSelected(e) {
    //   if (e.title === 'À distância') {

    //   }
    // },
    stopFetching() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    },
    startFetching() {
      this.intervalId = setInterval(this.fetchRequestDiscount, 3000)
    },
    generateUniqueNumber() {
      const timestamp = Date.now()
      const randomNum = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000
      
      return `${this.user.id}-${timestamp}-${randomNum}`
    },
    async fetchRequestDiscount() {
      this.$store
        .dispatch("Order/requestDiscountStatus", this.sessionId)
        .then((res) => {
          const hasStatusOne = res.some(item => item.status === 1);

          if (hasStatusOne) {
            this.manager.status = 1;
          } else {
            this.manager.status = 0;
          }
        })
    },
    changeManager(data) {
      this.manager = data
    },
    maxRequestDiscount(value) {
      return value >= 100 ? 100 : value
    },
    maxDiscount() {
      this.formGiveDiscount.percent = this.formGiveDiscount.percent <= this.discount.max ? this.formGiveDiscount.percent : this.discount.max;
    },
    submitUpdateAddress() {
      this.$v.formCustomerAddress.$touch();

      if (!this.$v.formCustomerAddress.$error) {
        this.btnSubmitUpdateAddress = true;

        this.$store
          .dispatch("Customer/fetchUpdateAddress", {
            uuid: this.customer.uuid,
            data: this.formCustomerAddress,
          })
          .then(() => {
            this.notifyDefault("success");
            this.getData()
            this.$bvModal.hide('modal-edit-cellphone')
          })
          .finally(() => {
            this.btnSubmitUpdateAddress = false;
          });
      }
    },
    searchCep() {
      if (this.formCustomerAddress.zipcode.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.formCustomerAddress.zipcode}/json/`)
          .then((res) => {
            const data = res.data;
            this.formCustomerAddress.address = data.logradouro;
            this.formCustomerAddress.neighborhood = data.bairro;
            this.formCustomerAddress.city = data.localidade;
            this.formCustomerAddress.uf = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    closeModalDismember() {      
      this.optionsInstallmentsSelected.map((el) => {
        if (el.installment === this.dismember.installment) {
          el.payments = []
        }
      })

      this.$bvModal.hide("modal-dismember-installment");
    },
    closeModalSaveDismember() {
      if (parseFloat(this.dismember.subtotal).toFixed(2) === parseFloat(this.dismember.value).toFixed(2)) {
        this.$bvModal.hide("modal-dismember-installment");
      } else {
        this.$toast({
        component: ToastificationContent,
          props: {
            title: 'Total da parcela inválido.',
            icon: 'BellIcon',
            text: `Falta cadastrar ${this.toCurrency(this.dismember.value - this.dismember.subtotal)} no desmembramento da parcela ${this.dismember.installment}`,
            variant: 'danger'
          },
        });
      }
    },
    closeModalDiscountRequest() {
      this.formRequestDiscount.percent = 0;
      this.formRequestDiscount.note = '';

      this.$bvModal.hide("modal-discount-request");
      this.generateInstallments()
    },
    async closeModalDiscountOfUser() {
      this.formGiveDiscount.percent = 0;
      this.formGiveDiscount.note = '';

      this.$bvModal.hide("modal-discount-apply");
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      let categoryId = null;

      switch(this.form.modality.title) {
        case 'Live':
          categoryId = 3;
          break;
        case 'Presencial':
          categoryId = 1;
          break;
        case 'Passaporte':
          categoryId = 7;
          break;
      }

      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [categoryId],
          })
          .then((res) => {
            this.optionsProducts = res.data;
          });
      }
    },
    submitCreateEnrollment() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.submited = true;

        if (this.form.letter) {
          this.form.letter.infos = this.letterCredit;
        }
        
        this.$store
          .dispatch("Order/createEnrollment", {
            uuid: this.$route.params.uuid,
            data: this.form,
          })
          .then((res) => {
            this.$router.push({
              name: "transactions-orders-preview",
              params: { uuid: res },
            });
          })
          .catch(() => {
            this.submited = false;
          });
      }
    },
    clearFormDismember() {
      this.formDismember = {
        method: "",
        brand: "",
        value: "",
      };
    },
    removeAtDismember(idx) {
      this.optionsInstallmentsSelected.map((element) => {

        element.payments.splice(idx, 1);

        const total = this.dismember.value;
        const subtotal = this.dismember.subtotal;

        if (subtotal <= total) {
          this.dismember.subtotal = 0;
          this.optionsInstallmentsSelected.map((el) => {
            if (el.installment === this.dismember.installment) {
              el.payments.map((el) => {
                this.dismember.subtotal = this.dismember.subtotal + el.value;
              });
            }
          });
        }
      });
    },
    addDismemberPayment() {
      this.$v.formDismember.$touch();

      if (this.$v.formDismember.$error) {
        this.$toast({
        component: ToastificationContent,
          props: {
            title: 'Campos obrigatórios',
            icon: 'BellIcon',
            text: 'Preencha os campos obrigatórios para cadastrar o pagamento.',
            variant: 'warning'
          },
        });
      } else {
        const total = this.dismember.value;
        const subtotal = this.dismember.subtotal;

        if (subtotal <= total) {
          this.dismember.subtotal = 0;
          this.optionsInstallmentsSelected.map((element) => {
            if (element.installment === this.dismember.installment) {
              element.payments.push(this.formDismember);

              element.payments.map((element) => {
                this.dismember.subtotal = this.dismember.subtotal + element.value;
              });
            }
          });
        }
        this.$v.$reset()
        this.clearFormDismember();
      }
    },
    dismemberInstallment(item) {
      this.clearFormDismember();
      this.dismember.installment = item.installment;
      this.dismember.value = item.value;
      this.dismember.payments = item.payments;

      this.dismember.payments.map((element) => {
        this.dismember.subtotal = this.dismember.subtotal + element.value;
      });

      this.$bvModal.show("modal-dismember-installment");
    },
    // Calcular o total de minutos de todas as disciplinas
    getTotalWorkloadMinutes(disciplines) {
      return disciplines.reduce((total, disciplina) => {
        return total + convertWorkloadToMinutes(disciplina.workload);
      }, 0);
    },
    installmentSelected(e) {
      const installment = this.form.payment.installment;
      this.optionsInstallmentsSelected = [];
      this.form.workload = 0;
      
      this.form.discount = {
        percent: 0,
        note: '',
        total: 0,
      }

      this.formGiveDiscount = {
        percent: 0,
        note: ''
      }

      this.formRequestDiscount = {
        percent: 0,
        note: ''
      }

      if (installment) {
        for (let index = 1; index <= installment.installments; index++) {
          this.optionsInstallmentsSelected.push({
            installment: index,
            value: installment.value,
            payments: [],
            date: moment().add(index, 'months').format('DD/MM/YYYY'),
            discountTotal: e.discountTotal,
            discount: e.discount
          });
        }

        this.form.installments = this.optionsInstallmentsSelected;
      }

      if (this.form.modality && this.form.modality.title === 'Presencial') {
        // Atualizar array de disciplinas selecionadas
        this.disciplinesSelecteds = this.disciplines.filter(d => d.selected = false);

        const totalValue = e.total;
        const totalMinutes = this.getTotalWorkloadMinutes(this.disciplines); // Total de minutos de todas disciplinas
        
        // Calcular o valor por minuto
        const valuePerMinute = totalValue / totalMinutes;

        // Atualizar cada disciplina com seu preço proporcional
        this.disciplines = this.disciplines.map(disciplina => {
          const minutes = convertWorkloadToMinutes(disciplina.workload);
          const price = minutes * valuePerMinute;
          
          return {
            ...disciplina,
            price: parseFloat(price).toFixed(2)
          };
        });
      }

      // if (this.form.letter.passport === 0) {
      //   console.log('this.form.letterthis.form.letterthis.form.letter', this.form.letter)
      //   this.generateLetterCreditNoPassport();
      // }
    },
    clearManager() {
      this.manager = {
        loading: false,
        discount: "",
        message: "",
        sent: false,
        description: "",
      };
    },
    submitGiveDiscount() {
      this.$v.formGiveDiscount.$touch();
      
      if (!this.$v.formGiveDiscount.$error) {
        this.$bvModal.hide('modal-discount-apply');
        
        const discount = this.formGiveDiscount;

        this.generateInstallments();
        
        this.form.discount = {
          percent: discount.percent,
          total: discount.total,
          note: discount.note,
        }
        this.installmentSelected(this.form.payment.installment)
        this.notifyDefault("success", "Desconto aplicado com sucesso.");
      }
    },
    submitGetDiscount() {
      this.manager.loading = true;
      this.manager.sent = 0;
      this.manager.status = 0;
      this.$v.formRequestDiscount.$touch();

      this.generateInstallments();

      if (!this.$v.formRequestDiscount.$error) {      
        let installment = {
          total: '',
          installments: '',
          value: '',
          title: '',
        };

        const discount = this.formRequestDiscount;

        for (let index = 0; index < this.optionsInstallments.length; index++) {
          if (this.optionsInstallments[index].code === this.form.payment.installment.code) {
            
            const total = this.optionsInstallments[index].total;
          
            const totalDiscount = parseFloat((total * discount.percent) / 100).toFixed(2);

            discount.total = totalDiscount;

            installment.total  = parseFloat(total - discount.total).toFixed(2);
            installment.value = parseFloat(total / this.optionsInstallments[index].installments).toFixed(2)
            installment.installments = this.optionsInstallments[index].installments;
            
            installment.title = `${this.optionsInstallments[index].installments}x de ${this.toCurrency(
              parseFloat(this.optionsInstallments[index].value)
            )} = ${this.toCurrency(parseFloat(installment.total))} ${discount.percent ? `(com -${discount.percent}%)` : ''}`
          }          
        }
       
        const data = {
          discount: discount,
          customer: this.customer.uuid,
          modality: this.form.modality,
          product: this.form.product,
          passport: this.form.passport,
          classroom: this.form.classroom,
          origin: this.form.origin,
          unit: this.form.unit,
          payment: this.form.payment,
          installment: installment,
          sessionId: this.sessionId
        };

        this.$store
          .dispatch("Order/requestDiscount", {
            uuid: this.$route.params.uuid,
            data: data,
          })
          .then(() => {
            this.manager.sent = 1;
            this.startFetching()
            this.$bvModal.hide("modal-discount-request");
          })
          .finally(() => {
            this.manager.loading = false;
          });
      }
    },
    getDiscount() {
      this.$bvModal.show("modal-discount-request");
    },
    modalitySelected() {
      this.optionsClassrooms = [];
      this.form.unit = "";
      this.form.classroom = "";
      this.form.payment.method = ""
      this.optionsPaymentMethods = []
      this.form.product = "";
      this.form.installments = [];
      this.form.payment.installment = "";
      this.optionsInstallmentsSelected = []
    },
    productSelected(e) {
      this.optionsClassrooms = [];
      this.form.unit = "";
      this.form.classroom = "";
      this.optionsInstallmentsSelected = []
   
      this.form.payment = {
        method: "",
        installment: "",
        brand: "",
        transaction_id: "",
      }
      
      this.form.installments = [];
      this.form.note = "";
      this.form.origin = "";
      this.form.discount = 0;
      
      if (!e) return false
      
      this.discount = this.userDiscounts.find(c => c.id === e.category_id);

      if (e.installments && e.installments.length) {
        this.installments = e.installments
        this.generatePaymentMethod()
      }

      this.$store
        .dispatch("Classroom/forSelectByProduct", e.code)
        .then((res) => {
          this.optionsClassrooms = res;
        });
    },
    generatePaymentMethod() {
      this.installments.forEach((item) => {
        if (
          item.card_debit &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "card_debit"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "card_debit",
            title: "Cartão de Débito",
          });
        }
        if (
          item.card_credit &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "card_credit"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "card_credit",
            title: "Cartão de Crédito",
          });
        }
        if (
          item.pix &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "pix"
          )
        ) {
          this.optionsPaymentMethods.push({ code: "pix", title: "PIX" });
        }
        if (
          item.money &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "money"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "money",
            title: "Dinheiro",
          });
        }
        if (
          item.check &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "check"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "check",
            title: "Cheque",
          });
        }
        if (
          item.billet &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "billet"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "billet",
            title: "Boleto",
          });
        }
        if (
          item.letter &&
          !this.optionsPaymentMethods.some(
            (method) => method.code === "letter"
          )
        ) {
          this.optionsPaymentMethods.push({
            code: "letter",
            title: "Carta de Crédito",
          });
        }
      });
    },
    getIconPaymentMethod(code) {
      switch (code) {
        case "card_credit":
          return "bi-credit-card-2-back";
        case "card_debit":
          return "bi-credit-card-2-front";
        case "billet":
          return "bi-upc-scan";
        case "letter":
          return "bi-file-earmark-medical";
        case "pix":
          return "bi-qr-code-scan";
        case "migracao":
          return "bi-arrow-down-up";
        case "cortesia":
          return "bi-gift";
        case "unit":
          return "bi-office";
        case "money":
          return "bi-cash-stack";
        case "check":
          return "bi-card-text";
        default:
          return "";
      }
    },
    async classroomSelected(classroom) {
      try {
        // Executa todas as chamadas assíncronas em paralelo
        const [
          acquirersResponse,
          managersResponse,
          installmentsResponse
        ] = await Promise.all([
          this.$store.dispatch("Acquirer/forSelectOfUnit", (classroom.unit?.code ?? this.user.unit.code)),
          this.$store.dispatch("User/managersDiscounts", {
            classroom: classroom.code,
            modality: this.form.modality.code,
          }),
          this.$store.dispatch("Classroom/installments", classroom.code)
        ]);

        // Atribuições dos resultados
        this.optionsAcquirers = acquirersResponse;
        this.managers = managersResponse;
        this.form.classroomMinutes = Number(classroom.workloadMinutes)
        this.installments = installmentsResponse;
        this.generatePaymentMethod();
        
        if (!classroom.product ) {
          this.notify(
            "Este curso não possui PRODUTO vinculado!",
            "BellIcon",
            "danger",
            null,
            10000
          ) 
        }
      } catch (error) {
        console.error('Erro ao processar classroomSelected:', error);
      }
    },
    async passaportSelected(el) {
      
      try {
        // Executa todas as chamadas assíncronas em paralelo
        // const [
        //   acquirersResponse,
        //   managersResponse,
          
        // ] = await Promise.all([
        //   this.$store.dispatch("Acquirer/forSelect"),
        //   this.$store.dispatch("User/managersDiscounts", {
        //     classroom: classroom.code,
        //     modality: this.form.modality.code,
        //   }),
        // ]);

        // // Atribuições dos resultados
        // this.optionsAcquirers = acquirersResponse;
        // this.managers = managersResponse;
        
        // Processamento dos installments
        
        this.optionsPaymentMethods = [];
        this.installments = [];
        this.optionsInstallments = [];
        this.optionsInstallmentsSelected = [];
        this.form.installments = "";
        this.form.payment.installment = "";
        this.form.payment.method = "";
        
        this.installments = el.installments;
        this.generatePaymentMethod();
      } catch (error) {
        console.error('Erro ao processar classroomSelected:', error);
      }
    },
    // changeFormPayment(e) {
    //   this.formDismember.brand = "";
    //   this.optionsInstallmentsSelected = [];
    //   this.form.payment.installment = "";
    //   this.form.payment.brand = "";
    //   this.form.installments = [];
      
    //   this.optionsInstallments = [];

    //   if (!e) return false

    //   this.generateInstallments()
    // },
    generateInstallments() {
      const paymentMethodMap = {
        "Cartão de Crédito": "card_credit",
        "Cartão de Débito": "card_debit",
        "PIX": "pix",
        "Cheque": "check",
        "Dinheiro": "money",
        "Boleto": "billet",
        "Carta de Crédito": "letter",
      };

      let paymentMethod = paymentMethodMap[this.form.payment.method.title] || "";
      this.optionsInstallments = [];

      const discount = Number(this.form.discount.percent ?? 0);
      const letterCreditMinutes = Number(convertWorkloadToMinutes(`${this.form.letter.hours}`));
      const letterCreditTotal = calculateCreditTotal(`${this.form.letter.hours}`, this.form.letter.price_hour);

      // Armazena o código da parcela selecionada antes de recalcular
      const selectedInstallmentCode = this.form.payment.installment?.code;

      this.installments
        .filter((parcela) => parcela[paymentMethod] === 1)
        .forEach((parcela) => {
          let total = parseInt(parcela.installment) * parseFloat(parcela.value);
          const originalTotal = total;
          let totalDiscount = 0;

          if (this.formGiveDiscount && this.formGiveDiscount.percent > 0) {
            const descontoEmPorcentagem = this.formGiveDiscount.percent / 100;
            totalDiscount += total * descontoEmPorcentagem;
          }

          if (discount > 0) {
            const descontoEmPorcentagem = discount / 100;
            totalDiscount += total * descontoEmPorcentagem;
          }

          if (this.form.letter) {
            if (
              this.form.letter.passport === 1 &&
              letterCreditMinutes < this.form.classroomMinutes
            ) {
              totalDiscount += letterCreditTotal;
              total = total - letterCreditTotal;
              parcela.value = total / Number(parcela.installment);
            } else if (this.form.letter.passport === 0) {
              totalDiscount += this.form.letter.total;
            }
          }

          total -= totalDiscount;
          parcela.value = total / Number(parcela.installment);

          const title = `${parcela.installment}x de ${this.toCurrency(
            parseFloat(parcela.value)
          )} = ${this.toCurrency(parseFloat(total))} ${totalDiscount ? `(-${this.toCurrency(totalDiscount)})` : ''}`;

          const installmentOption = {
            title: title,
            code: parcela.code,
            installments: parcela.installment,
            value: parcela.value,
            total: total,
            discountPercentage: discount,
            discountTotal: totalDiscount,
            note: parcela.note,
            originalTotal: originalTotal,
          };

          this.optionsInstallments.push(installmentOption);

          // Atualiza a parcela selecionada se o código corresponder
          if (selectedInstallmentCode && selectedInstallmentCode === parcela.code) {
            this.form.payment.installment = { ...installmentOption };
          }
        });
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    async getData() {
      this.$store
        .dispatch("Customer/show", this.$route.params.uuid)
        .then(async (res) => {
          this.customer = res.data;
          this.customer.cpf = maskCpf(this.customer.cpf);
          
          this.optionsUnits = await this.$store.dispatch("Unit/forSelect", 1);

          this.optionsModalities = await this.$store.dispatch(
            "ClassroomModality/forSelect"
          );

          this.$store.dispatch("LetterCredit/forSelectOfCustomer", this.customer.uuid)
          .then((res) => {
            this.optionsLetterCredit = res
          });

          if (this.$route.query.classroom) {
            this.$store.dispatch("Classroom/show", this.$route.query.classroom).then((res) => {
              this.form.modality = res.modality
              
              if (res.product) {
                this.form.product = res.product
                this.productSelected(this.form.product)
                this.form.unit = res.unit
                this.unitSelected(this.form.unit)
               
                this.form.classroom = { 
                  title: `${res.name} - ${moment(res.start_time, 'HH:mm:ss').format('HH:mm')} às ${moment(res.end_time, 'HH:mm:ss').format('HH:mm')} - ${res.course.title}`,
                  code: res.uuid
                }
                
                this.classroomSelected({ code: res.uuid })
              }
            });
          }
          
          this.formCustomerAddress = {
            zipcode: this.customer.cep,
            address: this.customer.address,
            complement: this.customer.complement,
            number: this.customer.number,
            city: this.customer.city,
            neighborhood: this.customer.neighborhood,
            uf: this.customer.uf,
          }
        });
    },

    async fetchUserDiscounts() {
      this.userDiscounts = await this.$store.dispatch("User/showDiscounts")
    },

    async fetchClassrooms(term) {
      if (term.length <= 1) {
        this.optionsClassrooms = [];
        this.optionsAcquirers = [];
        return;
      }

      try {
        const [classrooms, acquirers] = await Promise.all([
          this.$store.dispatch("Classroom/forSelectParams", {
            term,
            unit: null,
            modality: this.form.modality,
            status: 1,
            disciplines: this.form.modality.title === "Presencial" ? true : false,
          }),
        ]);

        this.optionsAcquirers = acquirers
        this.optionsClassrooms = classrooms;
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        this.optionsClassrooms = [];
        this.optionsAcquirers = [];
      }
    },
    async fetchDisciplinesByClassroom(term) {
      if (term.length < 2) {
        this.optionsDisciplinesClassroom = [];
        return;
      }

      try {
        this.$store.dispatch("Classroom/forSelectDisciplinesByParams", {
          term,
        }).then((res) => {
          this.optionsDisciplinesClassroom = res
        });
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        this.optionsDisciplinesClassroom = [];
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style lang="scss">
.modal-edit-cellphone {
  .modal-dialog {
    max-width: 300px;
  }
}

.btn-save-address {
  margin-top: 23px;
}
</style>
