var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreateEnrollment.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" Nome completo "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.customer.full_name) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" E-mail "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.customer.email) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group mb-1 mb-md-0" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" CPF "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.customer.cpf) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            class: _vm.customer.birth
                              ? "mb-1 mb-md-0"
                              : "mb-0 mb-md-0",
                          },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Celular "),
                            ]),
                            _vm._v(" " + _vm._s(_vm.customer.cellphone) + " "),
                            _c("i", {
                              staticClass:
                                "bi bi-pencil-square ml-2 text-primary cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-edit-cellphone"
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm.customer.birth
                        ? _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0 mb-md-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(" Nascimento "),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("datePT")(_vm.customer.birth)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["#####-###"],
                                expression: "['#####-###']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.zipcode,
                                expression: "formCustomerAddress.zipcode",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.formCustomerAddress.zipcode.$error,
                            },
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.formCustomerAddress.zipcode,
                            },
                            on: {
                              keyup: function ($event) {
                                return _vm.searchCep()
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "zipcode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm._m(2),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.address,
                                expression: "formCustomerAddress.address",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.formCustomerAddress.address.$error,
                            },
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.formCustomerAddress.address,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "address",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("label", [_vm._v("Número")]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.number,
                                expression: "formCustomerAddress.number",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.formCustomerAddress.number },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("label", [_vm._v("Complemento")]),
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.complement,
                                expression: "formCustomerAddress.complement",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.formCustomerAddress.complement,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "complement",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _vm._m(3),
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.neighborhood,
                                expression: "formCustomerAddress.neighborhood",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.formCustomerAddress.neighborhood.$error,
                            },
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.formCustomerAddress.neighborhood,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "neighborhood",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(4),
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.city,
                                expression: "formCustomerAddress.city",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.formCustomerAddress.city.$error,
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.formCustomerAddress.city },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "city",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(5),
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formCustomerAddress.uf,
                                expression: "formCustomerAddress.uf",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.formCustomerAddress.uf.$error,
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.formCustomerAddress.uf },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formCustomerAddress,
                                  "uf",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-save-address btn-block btn-warning",
                              staticStyle: {
                                padding: "5px 5px !important",
                                "line-height": "2.3",
                              },
                              attrs: {
                                disabled: _vm.btnSubmitUpdateAddress,
                                type: "button",
                              },
                              on: { click: _vm.submitUpdateAddress },
                            },
                            [
                              !_vm.btnSubmitUpdateAddress
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex items-center justify-content-center",
                                      staticStyle: { "column-gap": "5px" },
                                    },
                                    [
                                      _c("i", { staticClass: "bi bi-floppy" }),
                                      _c("span", [_vm._v(" Salvar ")]),
                                    ]
                                  )
                                : _c("b-spinner", {
                                    staticClass: "align-middle",
                                    attrs: { small: "" },
                                  }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _vm._m(6),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Nome completo")]),
                          _vm._v(" " + _vm._s(_vm.user.fullName) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("E-mail")]),
                          _vm._v(" " + _vm._s(_vm.user.email) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("label", [_vm._v("CPF")]),
                          _vm._v(" " + _vm._s(_vm.user.cpf) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("label", [_vm._v("Unidade")]),
                          _vm.user.unit
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.user.unit.title) + " "),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "mt-2" }, [
                          _vm._v("Follow up"),
                        ]),
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.note,
                                expression: "form.note",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              rows: "3",
                              placeholder:
                                "Caso tenha necessidade, escreva aqui uma observação para esta venda.",
                            },
                            domProps: { value: _vm.form.note },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "note", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          !_vm.customer.address
            ? _c("div", { staticClass: "alert alert-danger p-1" }, [
                _c(
                  "h5",
                  {
                    staticClass: "text-danger d-flex-left justify-content-left",
                    staticStyle: { "column-gap": "3px" },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                          width: "16",
                          height: "16",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z",
                            stroke: "currentColor",
                            "stroke-width": "1.5",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M11.9998 16H12.0088",
                            stroke: "currentColor",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M12 13L12 7",
                            stroke: "currentColor",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                    _c("strong", [_vm._v("Endereço do aluno ausente")]),
                  ]
                ),
                _vm._m(7),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._m(8),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "v-select",
                          {
                            class: {
                              "is-invalid": _vm.$v.form.modality.$error,
                            },
                            attrs: {
                              label: "title",
                              searchable: false,
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Selecione",
                              options: _vm.optionsModalities,
                            },
                            on: { input: _vm.modalitySelected },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(option.title),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.form.modality,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "modality", $$v)
                              },
                              expression: "form.modality",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.modality && _vm.form.modality.title !== "Passaporte"
                    ? _c("div", { staticClass: "col-md-8" }, [
                        _vm._m(9),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.form.classroom.$error,
                                },
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o código da turma",
                                  options: _vm.optionsClassrooms,
                                },
                                on: {
                                  search: _vm.fetchClassrooms,
                                  input: _vm.classroomSelected,
                                },
                                model: {
                                  value: _vm.form.classroom,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "classroom", $$v)
                                  },
                                  expression: "form.classroom",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Origem "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: "Onde está sendo feita a matrícula?",
                            expression: "'Onde está sendo feita a matrícula?'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "bi bi-question-circle",
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("v-select", {
                          class: { "is-invalid": _vm.$v.form.origin.$error },
                          attrs: {
                            searchable: false,
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsOrigins,
                          },
                          model: {
                            value: _vm.form.origin,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "origin", $$v)
                            },
                            expression: "form.origin",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.modality && _vm.form.modality.title === "Passaporte"
                    ? _c("div", { staticClass: "col-md-7" }, [
                        _vm._m(10),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.form.passport.$error,
                                },
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o código da turma",
                                  options: _vm.optionsProducts,
                                },
                                on: {
                                  search: _vm.fetchProducts,
                                  input: _vm.passaportSelected,
                                },
                                model: {
                                  value: _vm.form.passport,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "passport", $$v)
                                  },
                                  expression: "form.passport",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.modality && _vm.form.modality.title === "Passaporte"
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _vm._m(11),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.form.unit.$error,
                                },
                                attrs: {
                                  label: "title",
                                  searchable: false,
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Unidade",
                                  options: _vm.optionsUnits,
                                },
                                model: {
                                  value: _vm.form.unit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "unit", $$v)
                                  },
                                  expression: "form.unit",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.optionsLetterCredit.length &&
                  _vm.form.origin.title !== "À distância"
                    ? _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Carta de crédito")]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c("v-select", {
                              attrs: {
                                searchable: false,
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsLetterCredit,
                              },
                              model: {
                                value: _vm.form.letter,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "letter", $$v)
                                },
                                expression: "form.letter",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  !_vm.form.letter ||
                  Number(_vm.form.letter.workloadMinutes) <
                    _vm.form.classroomMinutes
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _vm._m(12),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid": _vm.$v.form.payment.method.$error,
                              },
                              attrs: {
                                searchable: false,
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsPaymentMethods,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (ref) {
                                      var code = ref.code
                                      var title = ref.title
                                      return [
                                        _c("i", {
                                          staticClass: "bi mr-75",
                                          class: _vm.getIconPaymentMethod(code),
                                        }),
                                        _c("span", [
                                          _vm._v(" " + _vm._s(title)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3828914721
                              ),
                              model: {
                                value: _vm.form.payment.method,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.payment, "method", $$v)
                                },
                                expression: "form.payment.method",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "check" ||
                    _vm.form.payment.method.code === "check")
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _vm._m(13),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.payment.check_number,
                                expression: "form.payment.check_number",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.payment.check_number },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.payment,
                                  "check_number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "check" ||
                    _vm.form.payment.method.code === "check") &&
                  _vm.form.origin.title !== "À distância"
                    ? _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(14),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.payment.bank_name,
                                expression: "form.payment.bank_name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.payment.bank_name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.payment,
                                  "bank_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "check" ||
                    _vm.form.payment.method.code === "check") &&
                  _vm.form.origin.title !== "À distância"
                    ? _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(15),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.payment.bank_agency,
                                expression: "form.payment.bank_agency",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.payment.bank_agency },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.payment,
                                  "bank_agency",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "check" ||
                    _vm.form.payment.method.code === "check") &&
                  _vm.form.origin.title !== "À distância"
                    ? _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(16),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.payment.bank_account,
                                expression: "form.payment.bank_account",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.payment.bank_account },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.payment,
                                  "bank_account",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "card_credit" ||
                    _vm.form.payment.method.code === "card_debit") &&
                  _vm.form.origin.title !== "À distância" &&
                  _vm.form.payment.method.code !== "letter"
                    ? _c("div", { staticClass: "col-md-2" }, [
                        _vm._m(17),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid": _vm.$v.form.payment.brand.$error,
                              },
                              attrs: {
                                searchable: false,
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsCardNetworks,
                                disabled: !_vm.form.payment.method,
                              },
                              model: {
                                value: _vm.form.payment.brand,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.payment, "brand", $$v)
                                },
                                expression: "form.payment.brand",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.origin.title !== "À distância" &&
                  _vm.form.modality.title !== "Passaporte" &&
                  _vm.form.payment.method &&
                  (_vm.form.payment.method.code === "card_credit" ||
                    _vm.form.payment.method.code === "card_debit")
                    ? _c("div", { staticClass: "col-md-2" }, [
                        _c("label", [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Adquirente "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.top",
                                value:
                                  "Maquininha usada ao efetuar o pagamento.",
                                expression:
                                  "'Maquininha usada ao efetuar o pagamento.'\n                  ",
                                modifiers: { hover: true, top: true },
                              },
                            ],
                            staticClass: "bi bi-question-circle",
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid":
                                    _vm.$v.form.payment.acquirer.$error,
                                },
                                attrs: {
                                  label: "name",
                                  "item-text": "name",
                                  "item-value": "uuid",
                                  options: _vm.optionsAcquirers,
                                  searchable: false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (option) {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(option.name),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3030077192
                                ),
                                model: {
                                  value: _vm.form.payment.acquirer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.payment, "acquirer", $$v)
                                  },
                                  expression: "form.payment.acquirer",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.origin.title === "À distância"
                    ? _c("div", { staticClass: "col-md-4 pt-2" }, [
                        _vm._v(
                          " O cliente receberá via e-mail, o link do checkout exclusivo para efetuar o pagamento. "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm.manager.status === 0 && _vm.manager.sent
            ? _c(
                "div",
                {
                  staticClass: "alert alert-primary px-2 py-2",
                  staticStyle: { "font-weight": "400", "font-size": "1.1rem" },
                },
                [
                  _c("i", {
                    staticClass: "bi bi-hourglass-split",
                    staticStyle: { "margin-right": "5px" },
                  }),
                  _vm._v(
                    " A solicitação foi enviada via WhatsApp para os gestores. "
                  ),
                  _vm._m(18),
                  _vm._v(". "),
                ]
              )
            : _vm._e(),
          _vm.manager.status === 1
            ? _c(
                "div",
                {
                  staticClass: "alert alert-success px-2 py-2",
                  staticStyle: { "font-weight": "400", "font-size": "1.1rem" },
                },
                [
                  _c("i", {
                    staticClass: "bi bi-check-circle",
                    staticStyle: { "margin-right": "5px" },
                  }),
                  _vm._v(" Sua solicitação de "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.form.discount.percent) + "% "),
                  ]),
                  _vm._v(" de desconto foi "),
                  _c("strong", { staticClass: "text-success" }, [
                    _vm._v("APROVADA"),
                  ]),
                  _vm._v("! "),
                ]
              )
            : _vm._e(),
          _vm.manager.status === 2
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger px-2 py-2",
                  staticStyle: { "font-weight": "400", "font-size": "1.1rem" },
                },
                [
                  _c("i", {
                    staticClass: "bi bi-x-circle",
                    staticStyle: { "margin-right": "5px" },
                  }),
                  _vm._v(" A sua solictação de desconto foi "),
                  _c("strong", { staticClass: "text-danger" }, [
                    _vm._v("REPROVADA"),
                  ]),
                  _vm._v("! "),
                ]
              )
            : _vm._e(),
          !_vm.form.letter ||
          Number(_vm.form.letter.workloadMinutes) < _vm.form.classroomMinutes
            ? _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _c("div", { staticClass: "card-header mb-0" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(19),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-4 d-flex-left justify-content-end",
                          staticStyle: { "column-gap": "5px" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                disabled: !_vm.form.payment.installment,
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-discount-apply"
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "bi bi-tag mr-50" }),
                              _vm._v(" Aplicar "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                disabled: !_vm.form.payment.installment,
                                type: "button",
                              },
                              on: { click: _vm.getDiscount },
                            },
                            [
                              _c("i", { staticClass: "bi bi-chat-dots mr-50" }),
                              _vm._v(" Solicitar "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-discounts-categories"
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "bi bi-info-circle" })]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-body p-2" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._m(20),
                        _c(
                          "div",
                          { staticClass: "form-group mb-md-0" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid":
                                    _vm.$v.form.payment.installment.$error,
                                },
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  options: _vm.optionsInstallments,
                                  searchable: false,
                                },
                                on: { input: _vm.installmentSelected },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (option) {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(option.title),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4146209711
                                ),
                                model: {
                                  value: _vm.form.payment.installment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.payment,
                                      "installment",
                                      $$v
                                    )
                                  },
                                  expression: "form.payment.installment",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("label", [
                          _vm._v(" Código da Venda "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.top",
                                value:
                                  "Código da venda, gerado ao efetuar o pagamento na adquirente.",
                                expression:
                                  "'Código da venda, gerado ao efetuar o pagamento na adquirente.'\n                  ",
                                modifiers: { hover: true, top: true },
                              },
                            ],
                            staticClass: "bi bi-question-circle",
                          }),
                        ]),
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.payment.transaction_id,
                                expression: "form.payment.transaction_id",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.form.payment.transaction_id,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.payment,
                                  "transaction_id",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.form.payment &&
          _vm.form.payment.installment &&
          _vm.form.payment.installment.total
            ? _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _vm._m(21),
                  _c(
                    "div",
                    { staticClass: "card-body p-0" },
                    [
                      _c("b-table", {
                        staticClass: "position-relative",
                        attrs: {
                          "sticky-header": "",
                          items: _vm.optionsInstallmentsSelected,
                          responsive: "",
                          fields: _vm.tableColumnsInstallments,
                          "show-empty": "",
                          striped: "",
                          hover: "",
                          "empty-text":
                            "Nenhuma parcela foi selecionada até o momento",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(installment)",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(" " + _vm._s(item.installment) + " "),
                                ]
                              },
                            },
                            {
                              key: "cell(value)",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.toCurrency(parseFloat(item.value))
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(date)",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.date,
                                        expression: "item.date",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: item.date },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "date",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "cell(payments)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "dark" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.payments.length) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-light btn-sm",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.dismemberInstallment(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-three-dots-vertical",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1305056459
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.form.letter && _vm.form.letter.passport === 1
            ? _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                },
                [
                  _vm._m(22),
                  _c("div", { staticClass: "card-body p-2" }, [
                    _vm.form.letter.passport
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("strong", [_vm._v("Total de horas: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.letterCredit.originalHours) + "h"
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("strong", [_vm._v("Carga horária da turma: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.classroom.workload)),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("strong", [_vm._v("Horas a usar: ")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.letterCredit.usedHours) + "h"),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("strong", [_vm._v("Horas restantes: ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.letterCredit.remainingHours) + "h"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "form-row justify-content-end modal-footer-custom" },
            [
              _c(
                "div",
                { staticClass: "col-md-2 col-12" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { 400: true },
                        },
                      ],
                      staticClass: "mr-2",
                      attrs: {
                        variant: "success",
                        type: "submit",
                        block: "",
                        disabled:
                          !_vm.customer.address ||
                          _vm.submited ||
                          _vm.manager.status === 0,
                      },
                    },
                    [
                      _vm.submited
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { small: "", variant: "light" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [_vm._v("Concluir matrícula")]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-edit-cellphone",
            "modal-class": "modal-edit-cellphone",
            title: "Editar celular",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["(##) #####-####", "(##) ####-####"],
                    expression: "['(##) #####-####', '(##) ####-####']",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formCustomerAddress.cellphone,
                    expression: "formCustomerAddress.cellphone",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "tel",
                  name: "cellphone",
                  placeholder: "(00) 00000-0000",
                },
                domProps: { value: _vm.formCustomerAddress.cellphone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formCustomerAddress,
                      "cellphone",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning",
                  staticStyle: {
                    padding: "5px 5px !important",
                    "line-height": "2.5",
                  },
                  attrs: {
                    disabled: _vm.btnSubmitUpdateAddress,
                    type: "button",
                  },
                  on: { click: _vm.submitUpdateAddress },
                },
                [
                  !_vm.btnSubmitUpdateAddress
                    ? _c("i", { staticClass: "bi bi-floppy" })
                    : _c("b-spinner", {
                        staticClass: "align-middle",
                        attrs: { small: "" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header-close": "",
            id: "modal-dismember-installment",
            "modal-class": "modal-dismember",
            "title-html":
              "Desmembrar <strong>" +
              _vm.dismember.installment +
              "ª parcela</strong>",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "body-class": "p-0",
          },
        },
        [
          _c(
            "b-card-body",
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Forma de Pagamento "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.formDismember.method.$error,
                        },
                        attrs: {
                          searchable: false,
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsPaymentMethods,
                        },
                        on: {
                          input: function ($event) {
                            _vm.formDismember.bran = ""
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (ref) {
                              var code = ref.code
                              var title = ref.title
                              return [
                                _c("i", {
                                  staticClass: "bi mr-75",
                                  class: _vm.getIconPaymentMethod(code),
                                }),
                                _c("span", [_vm._v(" " + _vm._s(title))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.formDismember.method,
                          callback: function ($$v) {
                            _vm.$set(_vm.formDismember, "method", $$v)
                          },
                          expression: "formDismember.method",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.formDismember.method &&
                _vm.formDismember.method.code === "letter"
                  ? _c("div", { staticClass: "col-md-5" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Carta de crédito"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c("v-select", {
                            attrs: {
                              searchable: false,
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsLetterCredit,
                            },
                            model: {
                              value: _vm.formDismember.letter,
                              callback: function ($$v) {
                                _vm.$set(_vm.formDismember, "letter", $$v)
                              },
                              expression: "formDismember.letter",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.formDismember.method &&
                _vm.formDismember.method.code === "letter"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Crédito"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c(
                            "money",
                            _vm._b(
                              {
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.formDismember.value.$error,
                                },
                                attrs: { placeholder: "0,00" },
                                on: { input: _vm.limitLetter },
                                model: {
                                  value: _vm.formDismember.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formDismember, "value", $$v)
                                  },
                                  expression: "formDismember.value",
                                },
                              },
                              "money",
                              _vm.money,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.formDismember.method.title === "Cartão de Crédito" ||
                _vm.formDismember.method.title === "Cartão de Débito"
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Bandeira "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid": _vm.$v.formDismember.brand.$error,
                            },
                            attrs: {
                              disabled:
                                _vm.formDismember.method.title !==
                                  "Cartão de Crédito" &&
                                _vm.formDismember.method.title !==
                                  "Cartão de Débito",
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsCardNetworks,
                              searchable: false,
                            },
                            model: {
                              value: _vm.formDismember.brand,
                              callback: function ($$v) {
                                _vm.$set(_vm.formDismember, "brand", $$v)
                              },
                              expression: "formDismember.brand",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.formDismember.method &&
                _vm.formDismember.method.code !== "letter"
                  ? _c("div", { staticClass: "col-md-3" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Valor "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c(
                            "money",
                            _vm._b(
                              {
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.formDismember.value.$error,
                                },
                                attrs: { placeholder: "0,00" },
                                model: {
                                  value: _vm.formDismember.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formDismember, "value", $$v)
                                  },
                                  expression: "formDismember.value",
                                },
                              },
                              "money",
                              _vm.money,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-1 d-flex-left" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      staticStyle: {
                        "line-height": "2.3",
                        "margin-top": "20px",
                      },
                      attrs: {
                        type: "button",
                        disabled:
                          this.dismember.subtotal >= this.dismember.value,
                      },
                      on: { click: _vm.addDismemberPayment },
                    },
                    [_c("i", { staticClass: "bi bi-plus-circle" })]
                  ),
                ]),
              ]),
              _c("hr"),
              _c("h5", { staticClass: "mb-0" }, [_vm._v("Desmembramento")]),
              _c("p", { staticClass: "mb-2 mt-50" }, [
                _vm._v(" Cadastre os pagamentos referente a parcela "),
                _c("strong", [_vm._v(_vm._s(_vm.dismember.installment))]),
                _vm._v(
                  ". A soma dos pagamentos tem que totalizar " +
                    _vm._s(_vm._f("toCurrency")(_vm.dismember.value)) +
                    ". "
                ),
              ]),
              _vm._l(_vm.dismember.payments, function (element, index) {
                return _c("div", { key: index, staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("v-select", {
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsPaymentMethods,
                            searchable: false,
                            placeholder: "Método de pagamento",
                            disabled: true,
                          },
                          model: {
                            value: element.method,
                            callback: function ($$v) {
                              _vm.$set(element, "method", $$v)
                            },
                            expression: "element.method",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("v-select", {
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsCardNetworks,
                            searchable: false,
                            placeholder: "Bandeira",
                            disabled: true,
                          },
                          model: {
                            value: element.brand,
                            callback: function ($$v) {
                              _vm.$set(element, "brand", $$v)
                            },
                            expression: "element.brand",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "money",
                          _vm._b(
                            {
                              staticClass: "form-control",
                              attrs: { placeholder: "0,00", disabled: true },
                              model: {
                                value: element.value,
                                callback: function ($$v) {
                                  _vm.$set(element, "value", $$v)
                                },
                                expression: "element.value",
                              },
                            },
                            "money",
                            _vm.money,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-block btn-sm text-center btn-danger text-danger",
                        staticStyle: {
                          padding: "4px 9px !important",
                          height: "44px",
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAtDismember(index)
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "XCircleIcon", size: "16" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              _vm.dismember.payments.length === 0
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " Nenhum desmembramento foi cadastrado até o momento. "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("b-card-footer", [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-10 text-right" }, [
                _vm._v("Cadastrado até o momento:"),
              ]),
              _c("div", { staticClass: "col-md-2 text-right text-primary" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("toCurrency")(_vm.dismember.subtotal))),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-row mt-50" }, [
              _c("div", { staticClass: "col-md-10 text-right" }, [
                _vm._v("Pagamento restante:"),
              ]),
              _c("div", { staticClass: "col-md-2 text-right text-danger" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.dismember.value - _vm.dismember.subtotal
                      )
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-row mt-50" }, [
              _c("div", { staticClass: "col-md-10 text-right" }, [
                _vm._v("Total da parcela:"),
              ]),
              _c("div", { staticClass: "col-md-2 text-right text-success" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("toCurrency")(_vm.dismember.value))),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticClass: "form-row justify-content-end mt-50" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-light",
                    attrs: { type: "button" },
                    on: { click: _vm.closeModalDismember },
                  },
                  [_vm._v(" Limpar ")]
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success",
                    attrs: { type: "button" },
                    on: { click: _vm.closeModalSaveDismember },
                  },
                  [_vm._v(" Salvar ")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-discount-request",
            "modal-class": "modal-customer-add-product",
            title: "Solicitar desconto",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitGetDiscount.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row align-items-center" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", { staticClass: "text-success mb-0" }, [
                    _vm._v(
                      " O desconto será aplicado após aprovação do gestor. "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Quantos % deseja conceder? "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formRequestDiscount.percent,
                          expression: "formRequestDiscount.percent",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formRequestDiscount.percent.$error,
                      },
                      attrs: {
                        type: "number",
                        max: "100",
                        placeholder: "%",
                        disabled: _vm.manager.loading,
                        pattern: "\\d*",
                      },
                      domProps: { value: _vm.formRequestDiscount.percent },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formRequestDiscount,
                              "percent",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.maxRequestDiscount(
                              _vm.formRequestDiscount.percent
                            )
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo do desconto "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formRequestDiscount.note,
                          expression: "formRequestDiscount.note",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formRequestDiscount.note.$error,
                      },
                      domProps: { value: _vm.formRequestDiscount.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formRequestDiscount,
                            "note",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "button" },
                      on: { click: _vm.closeModalDiscountRequest },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" Solicitar ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-discount-apply",
            "modal-class": "modal-customer-add-product",
            title: "Conceder desconto",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitGiveDiscount.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row align-items-center" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", { staticClass: "text-success mb-0" }, [
                    _vm._v(" Você tem permissão para conceder até "),
                    _c("strong", [_vm._v(_vm._s(_vm.discount.max) + "%")]),
                    _vm._v(" de desconto. "),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Quantos % deseja conceder? "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formGiveDiscount.percent,
                          expression: "formGiveDiscount.percent",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formGiveDiscount.percent.$error,
                      },
                      attrs: {
                        type: "number",
                        max: _vm.discount.max,
                        placeholder: "%",
                        pattern: "\\d*",
                      },
                      domProps: { value: _vm.formGiveDiscount.percent },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formGiveDiscount,
                              "percent",
                              $event.target.value
                            )
                          },
                          _vm.maxDiscount,
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo do desconto "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formGiveDiscount.note,
                          expression: "formGiveDiscount.note",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formGiveDiscount.note.$error,
                      },
                      domProps: { value: _vm.formGiveDiscount.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formGiveDiscount,
                            "note",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModalDiscountOfUser()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" Conceder desconto ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: "modal-discounts-categories",
            title: "Permissão para descontos",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-card-text",
            [
              _c("p", [
                _vm._v(
                  " Veja abaixo o percentual de desconto que você poderá conceder para cada categoria de produto. "
                ),
              ]),
              _c(
                "b-list-group",
                { staticClass: "list-group-messages" },
                _vm._l(_vm.userDiscounts, function (element, index) {
                  return _c("b-list-group-item", { key: index }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-8 text-left col-8" }, [
                        _vm._v(" " + _vm._s(element.name) + " "),
                      ]),
                      _c("div", { staticClass: "col-md-4 text-center col-4" }, [
                        _vm._v(" Até " + _vm._s(element.max) + "% "),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Dados do aluno "),
        _c("small", { staticClass: "d-block mt-50" }, [
          _vm._v("Confirme os dados do aluno antes de realizar a matrícula."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" CEP"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Endereço"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Bairro"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Cidade"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" UF"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Dados do atendente "),
        _c("small", { staticClass: "d-block mt-50" }, [
          _vm._v("Verifique se seus dados estão corretos"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Antes de gerar a matrícula, é necessário cadastrar o endereço do aluno. Preencha os campos acima com o endereço do aluno e clique no botão "
      ),
      _c("u", [_vm._v("Salvar")]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Modalidade "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Turma "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Passaporte "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Unidade "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Forma de Pagamento "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Nº do cheque"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Banco"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Agência"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Conta bancária"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Bandeira"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [_c("u", [_vm._v("NÃO ATUALIZE A PÁGINA!")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-8" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Pagamento "),
        _c(
          "small",
          {
            staticClass:
              "d-flex justify-content-left items-center mt-50 text-dark",
          },
          [
            _c("strong", [
              _vm._v(
                "Ao selecionar o parcelamento, as opções de desconto serão habilitadas "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("i", { staticClass: "text-danger bi bi-record-circle" }),
      _vm._v(" Parcelamento"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header mb-0" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Parcelas "),
        _c("small", { staticClass: "d-block mt-50" }, [
          _vm._v(
            "Caso o cliente queira efetuar o pagamento de 1 ou mais parcelas, usando formas de pagamento diferentes, é só fazer desmembramento. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header mb-0" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Carta de crédito "),
        _c("small", { staticClass: "d-block mt-50" }, [
          _vm._v(
            " Detalhes sobre o uso da cartá de crédito do aluno após a matrícula do aluno. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }